.shop-name {
  text-align: left;
  flex: 1;
  max-width: 70%;
}
.shop-name a:focus,
.shop-name a {
  color: white;
}

.shop-icon {
  color: white;
}

.cart-counter {
  left: 111% !important;
}

.cart-link:focus,
.cart-link {
  color: white;
}

/* search button */
.ui.button.circular {
  color: white;
  background: transparent;
  margin: -0.5em 0 !important;
  font-size: 1.37rem !important;
}
.ui.input.search input,
.ui.input.search input:focus,
.ui.input.search input:active {
  border-radius: 50px;
  min-width: 220px;
}
